/**
 * External dependencies
 */
import $ from 'jquery';


// Read More

$('.js-read-more').on('click', function (e) {
	e.preventDefault();
	
	const $this = $(this);

	$this
		.siblings('.js-read-more-text')
			.toggleClass('is-open');

	$this.remove();
});
