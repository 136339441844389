/**
 * External dependencies
 */
import $ from 'jquery';

/**
 * Internal dependencies
 */
import { $body, $win } from '../utils/globals';

// Constants
const SMALL_DESKTOP_WIDTH = 1199;
const $header = $( '.js-header' );

// Toggle mobile menu
$( '.js-nav-toggle' ).on( 'click', function (e) {
	e.preventDefault();

	$body.toggleClass( 'has-open-header' );
});

// Mobile Menu
$( '.js-nav .menu-item-has-children > a' ).on( 'click', function ( e ) {
	const $currentItem = $( this ).parent();

	if ( $win.width() <= SMALL_DESKTOP_WIDTH && ! $currentItem.hasClass('is-open') ) {
		e.preventDefault();

		$currentItem.toggleClass( 'is-open' );
	}
} );

// Change header height CSS varible depending on actual header height
$win.on('load resize orientationchange', function () {
	$body.css('--theme-header-height', `${$header.outerHeight()}px`);
});

// Handle header sticky appearence
let latestScroll = 0;

$win.on( 'scroll', function () {
	let currentScroll = $(this).scrollTop();

	$body.toggleClass( 'is-scrolled', currentScroll > 0 );
	
	latestScroll = currentScroll;
});
