/**
 * External dependencies
 */
import Swiper from 'swiper/bundle';

// Slider Hero
new Swiper('.js-slider-hero .swiper', {
	speed: 800,
	slidesPerView: 1,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
});

// Slider Gallery
new Swiper('.js-slider-gallery .swiper', {
	speed: 800,
	slidesPerView: 'auto',
	navigation: {
		prevEl: '.js-slider-gallery .swiper-button-prev',
		nextEl: '.js-slider-gallery .swiper-button-next',
	},
	breakpoints: {
		320: {
			spaceBetween: 10,
		},
		768: {
			spaceBetween: 20,
		}
	}
});

// Slider Testimonials
new Swiper('.js-slider-testimonials .swiper', {
	speed: 800,
	slidesPerView: 1,
	effect: 'fade',
	loop: true,
	fadeEffect: {
		crossFade: true,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

// Slider Services
new Swiper('.js-slider-services .swiper', {
	speed: 800,
	slidesPerView: 'auto',
	spaceBetween: 10,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

// Slider Images
new Swiper('.js-slider-images .swiper', {
	speed: 800,
	slidesPerView: 'auto',
	//loop: true,
	autoplay: {
		delay: 2000,
	},
	breakpoints: {
		320: {
			spaceBetween: 10,
		},
		768: {
			spaceBetween: 20,
		}
	}
});

// Slider Projects

new Swiper('.js-slider-projects .swiper', {
	speed: 800,
	slidesPerView: 1,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	autoplay: {
		delay: 5000,
	}
});
