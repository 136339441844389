/**
 * External dependencies
 */
import $ from 'jquery';

// Append section to members
$('.js-section-members').each((index, element) => {
	const $this = $(element);
	const $sectionTitleButton = $this.next('.js-section-title-button');
	const $members = $this.find('.js-members');

	if ( $members.children().length % 3 === 1 ) {
		const $titleButtonCopy = $sectionTitleButton.clone();

		$titleButtonCopy.addClass('hidden-lg hidden-xs');
		$sectionTitleButton.addClass('hidden-md hidden-sm');

		$members.append($titleButtonCopy);
	}
});
