/**
 * External dependencies
 */
import $ from 'jquery';

/**
 * Internal dependencies
 */
import { $body } from '../utils/globals';

$('.js-popup-trigger').on('click', function (e) {
	e.preventDefault();

	const $target = $($(this).attr('href'));

	$target.toggleClass('is-open');
	$body.toggleClass('is-locked');
});
