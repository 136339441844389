/**
 * Internal dependencies
 */
import { $body } from '../utils/globals';

// Load Animation

$body.addClass('is-animating');

setTimeout(() => {
	$body.removeClass('is-animating load-animation-processing');
}, 4200);
