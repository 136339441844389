/**
 * External dependencies
 */
import $ from 'jquery';

/**
 * Internal dependencies
 */
import { $win } from '../utils/globals';

// Words Autopaly
$('.js-words-autoplay').each((index, element) => {
	let activeWordIndex = 0;
	const $this = $(element);
	const $words = $this.find('span');

	$win.on('load resize orientationchange', function () {
		$this.css('--height', `${$words.eq(0).outerHeight()}px`).addClass("is-active");
	});

	setInterval(() => {console.log("interval");
		if ( activeWordIndex == $words.length - 1 ) {
			activeWordIndex = 0;
		} else {
			activeWordIndex++;
		}

		$this.css('--progress', `-${activeWordIndex * 100}%`);
	}, 3000);
});
