import $ from "jquery";
import { $body } from "../utils/globals";

const $projectsCnt = $("#all-projects");
if ($projectsCnt.length) {
    projectFilters();
}


function projectFilters() {
    const $filters = $projectsCnt.find(".js-project-filters a");
    if (!$filters.length) { console.log("no project filters found"); return; }

    $filters.on("click", filterClicked);

    function filterClicked(e) {
        e.preventDefault();
        const $this = $(this);
        const link = $this.attr("href");
        if (link === "#") return;
        getProjects(link);
        $filters.removeClass("is-active");
        $this.addClass("is-active");
        $projectsCnt.toggleClass("is-filtered", link.includes("service=all") != true || link === "#");
        $this.closest(".js-dropdown").removeClass("is-open");
    }
    function getProjects(url) {
        $.ajax({
            type: "GET",
            url: url + '&ajax',
            success: projectPage => {
                const $projectPg = $(projectPage);
                const $projectsParent = $projectPg.find(".section__images");
                if (!$projectsParent.length) { console.warn("no projects returned"); return; }
                updateProjects($projectsParent);
            },
        });
    }
    function updateProjects($projectsParent) {
        $body.find(".section__images").replaceWith($projectsParent);
    }
}