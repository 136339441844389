/**
 * External dependencies
 */
import $ from 'jquery';
import Swiper from 'swiper/bundle';

/**
 * Internal dependencies
 */
import { $body } from '../utils/globals';

// Projects Gallery
$('.js-gallery').each((_, gallery) => {
	let $gallery = $(gallery);
	const $sliderPopup = $gallery.siblings('.js-slider-popup').appendTo($body);
	const $sec = $gallery.closest(".section-gallery");
	let sliderSlides = null;

	initSliders();
	initPopup();
	initFilters();


	function initPopup() {
		const $sliderClose = $sliderPopup.find('.js-gallery-close');

		$gallery.on('click', '.image-title', openPopup);
		$sliderClose.on('click', closePopup);

		function openPopup(e) {
			e.preventDefault();
			$sliderPopup.addClass('is-open');
			$body.addClass('is-locked');
			if (sliderSlides) sliderSlides.slideTo($(this).index());
		}
		function closePopup(e) {
			e.preventDefault();
			$sliderPopup.removeClass('is-open');
			$body.removeClass('is-locked');
		}
	}
	function initSliders() {
		const sliderBodyElement = $sliderPopup.find('.swiper-body')[0];
		const sliderNavigationElement = $sliderPopup.find('.swiper-thumbs')[0];

		const sliderNavigation = new Swiper(sliderNavigationElement, {
			slidesPerView: 'auto',
			freeMode: true,
			watchSlidesProgress: true,
			breakpoints: {
				320: {
					spaceBetween: 10,
				},
				768: {
					spaceBetween: 30,
				}
			}
		});
	
		sliderSlides = new Swiper(sliderBodyElement, {
			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},
			thumbs: {
				swiper: sliderNavigation,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
	}
	function initFilters() {
		const $filters = $sec.find(".gallery-filters li");

		$filters.on("click", "a", filterClicked);

		function filterClicked(e) {
			e.preventDefault();
			const $this = $(this);
			const link = $this.attr("href");
			if (link === "#") return;
			getProjects(link);
			$filters.removeClass("is-active");
			//$this.parent().addClass("is-active");
			const filterSlug = $this.parent().data("filter");
			$filters.filter(`[data-filter="${filterSlug}"]`).addClass("is-active");
			//$sec.toggleClass("is-filtered", link.includes("service=all") != true || link === "#");
			$sec.addClass("is-filtered");
			$this.closest(".js-dropdown").removeClass("is-open");
		}
		function getProjects(url) {
			$.ajax({
				type: "GET",
				url: url + '&ajax',
				success: galleryPage => {
					const $galleryPg = $(galleryPage);
					const $galleryParent = $galleryPg.find(".section__gallery");
					if (!$galleryParent.length) { console.warn("no projects returned"); return; }
					updateGallery($galleryParent);
					initSliders();
					initPopup();
				},
			});
		}
		function updateGallery($galleryParent) {
			$body.find(".section__gallery").replaceWith($galleryParent);
			$gallery = $sec.find(".js-gallery");
		}
	}
});
