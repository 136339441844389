/**
 * External dependencies
 */
import $ from 'jquery';

// Dropdown Functionality

$('.js-dropdown > a').on('click', function (e) {
	e.preventDefault();

	$(this)
		.closest('.js-dropdown')
			.toggleClass('is-open');
});
